import { Directive, HostBinding } from '@angular/core';

const base =
  'block w-full rounded-lg border-0 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-mango sm:leading-6 shadow-sm placeholder:text-mango-lightGray';

@Directive({
  selector: 'input[input-date], textarea[textarea-style]',
  standalone: true,
})
export class InputDateDirective {
  @HostBinding('class') twClasses = base;

  constructor() {}
}
